import './App.css';
import RevolutCheckout from '@revolut/checkout'

RevolutCheckout('pk_7Uz2YmcLsu75L0vO6V7MSXt02YmrP4lbEQf2d46LvVKShuFr', 'sandbox').then((instance) => {
  instance.payWithPopup({
    onSuccess() {
      window.alert("Thank you!");
    },
    onError(message) {
      window.alert("Oh no :(");
    },
  });
})

function App() {
  return (
    <div className="App">
      TEST
    </div>
  );
}

export default App;
